<!-- 质检分类页面 -->
<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="testSort-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <el-button class="common-screen-btn" type="primary" @click="addDrawer('add', 1, 0)"
      >添加质检分类
    </el-button>
    <!-- 树形表格 -->
    <tree-table
      class="treeTable"
      :data="catelist"
      :columns="columns"
      :selection-type="false"
      :expand-type="false"
      :show-row-hover="false"
      :is-fold="false"
      children-prop="childrenList"
    >
      <!-- 操作 -->
      <template slot="opt" slot-scope="scope">
        <el-button
          v-if="scope.row.hierarchy == 1"
          type="text"
          size="mini"
          @click="addDrawer('add', 2, scope.row.id)"
          >新增问题点</el-button
        >
        <el-button
          v-if="scope.row.hierarchy == 2"
          type="text"
          size="mini"
          @click="addDrawer('add', 3, scope.row.id)"
          >新增问题说明</el-button
        >
        <el-button
          type="text"
          size="mini"
          @click="addDrawer('edit', scope.row.hierarchy, scope.row.id, scope.row)"
          >编辑
        </el-button>
        &nbsp;&nbsp;&nbsp;
        <el-popconfirm
          placement="top"
          title="确定删除吗？"
          @confirm="() => deleteItem(scope.row.id)"
        >
          <el-button type="text" size="small" slot="reference">删除</el-button>
        </el-popconfirm>
      </template>
    </tree-table>

    <!-- 分页 -->
    <el-pagination
      style="text-align: center"
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>

    <test-sort-add
      ref="testSortAdd"
      :drawer="drawer"
      @handleClose="handleClose"
    ></test-sort-add>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import TestSortAdd from "./children/testSortAdd.vue";
import { testSortList, deleteQuality } from "../../service/service.js";
import { mapState } from "vuex";

export default {
  components: {
    Breadcrumb,
    TestSortAdd,
  },
  data() {
    return {
      loading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "客服", isLink: false },
        { title: "质检分类", isLink: false },
      ],
      params: {
        page: 1,
        pageSize: 10,
        type: "quality",
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      // 树形数据列表，默认为空
      catelist: [],
      // 为table指定列的定义
      columns: [
        {
          label: "质检分类",
          prop: "name",
        },
        {
          label: "扣分",
          prop: "pointDeduction",
          width: "100px",
        },
        {
          label: "扣款金额",
          prop: "amountDeduction",
          width: "100px",
        },
        {
          label: "操作",
          // 表示，将当前列定义为模板列
          type: "template",
          // 表示当前这一列使用模板名称
          template: "opt",
          width: "220px",
        },
      ],
      drawer: false, // 添加质检分类显隐
      addType: null,

    };
  },
  computed: {
    ...mapState(["getUserInfo","comId"])
  },
  created() {
    this.getComId()
    // this.testSortList();
  },
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.testSortList();
      },400)
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.testSortList();
    },
    async testSortList() {
      this.loading = true;
      let params = { ...this.params };
      let resData = (await testSortList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.catelist = resData.list;
      console.log(resData);
      this.loading = false;
    },
    editPassword(e) {
      // 修改密码弹窗
      this.centerDialogVisible = true;
      console.log(e);
    },
    handleClose() {
      this.drawer = false;
      this.testSortList();
      // 关闭新增员工弹窗
      // done();
    },
    addDrawer(type, level, id, row) {
      // 添加编辑 -- type: 新增/编辑 ,level：层级判断, id：编辑传参
      this.drawer = true;
      this.$refs["testSortAdd"].getInfo({ id, type, level }, row);
    },
    async deleteItem(id) {
      await deleteQuality({}, id);
      this.$message.success("删除成功");
      this.testSortList();
    },
    // 新增问题
    addQuestions(id) {
      console.log(id);
      this.addType = id;
      this.quDrawer = true;
    },
    addQuestionShow(id) {
      console.log(id);
      this.quesDrawer = true;
    },
    edit(id) {
      console.log(id);
      this.editDrawer = true;
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.testSort-container {
  text-align: left;
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
.common-screen-btn {
  margin-left: 0;
}
.zk-table {
  margin: 16px 0;
}
/deep/ .zk-table__header-cell {
  font-weight: 400 !important;
}
/deep/ .zk-table__header-row {
  background-color: transparent;
}
</style>
